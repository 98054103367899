<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="dense-wrapper">
          <v-col cols="6" md="6">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва коротка" v-model="short_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва повна" v-model="full_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="full_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col class="pa-2 mx-3 grey lighten-4">
            <div class="command-panel mt-2">
              <v-btn small
                     :dark="formValid"
                     class="success mr-2" :disabled="!formValid"
                     @click.stop="addRow"
              >
                <v-icon small left>mdi-plus</v-icon>
                Додати рядок
              </v-btn>
              <v-btn small
                     :dark="formValid"
                     class="error lighten-1" :disabled="!formValid"
                     @click.stop="clearDialog"
              >
                <v-icon small left>mdi-close</v-icon>
                Очистити таблицю
              </v-btn>
            </div>
            <div class="document-table with-command mt-2">
              <div class="document-table-body">
                <div class="document-table-header">
                  <div class="document-table-row">
                    <div class="document-table-col" style="flex: 0 0 70px">
                      № п/п
                    </div>
                    <div class="document-table-col" style="flex: 0 0 300px">
                      Рахунок
                    </div>
                    <div class="document-table-col-group" style="flex: 0 0 150px"
                         v-if="show_detail_1 || show_detail_2 || show_detail_3"
                    >
                      <div class="document-table-col" v-if="show_detail_1">
                        Субконто 1
                      </div>
                      <div class="document-table-col" v-if="show_detail_2">
                        Субконто 2
                      </div>
                      <div class="document-table-col" v-if="show_detail_3">
                        Субконто 3
                      </div>
                    </div>
                    <div class="document-table-col" style="flex: 0 0 90px">
                      Відсоток
                    </div>
                  </div>
                </div>
                <div class="document-table-row"
                     v-for="(charge) in table"
                     :key="`charge-${charge.row_num}`"
                >
                  <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                    <div style="flex: 1; padding-left: 4px">
                      {{ table.indexOf(charge) + 1 }}
                    </div>
                    <div style="flex:  0 0 32px">
                      <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                        <v-icon small color="grey darken-2">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div class="document-table-col" style="flex: 0 0 300px">
                    <select_input
                        v-model="charge.chart_of_account_id"
                        :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                        :computed-getter="'get_chart_of_accounts_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="charge.row_num"
                        :show-select-on-focus="false"
                        :show-search-on-focus="true"
                        small
                        transparent
                        :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true
                              }"
                        :required="true"
                        @selectChange="afterPayChartAccountChange"
                    />
                  </div>
                  <div class="document-table-col-group" style="flex: 0 0 150px"
                       v-if="show_detail_1 || show_detail_2 || show_detail_3">
                    <div class="document-table-col" v-if="show_detail_1">
                      <select_input
                          v-model="charge.account_detail_1_value"
                          :computed-action="subconto_type_select_setting[charge.account_detail_1_type].computed_action"
                          :computed-getter="subconto_type_select_setting[charge.account_detail_1_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="charge.row_num"
                          :show-select-on-focus="false"
                          small
                          transparent
                          :required="true"
                          :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"/>
                    </div>
                    <div class="document-table-col" v-if="show_detail_2">
                      <select_input
                          v-if="charge.account_detail_2_type"
                          v-model="charge.account_detail_2_value"
                          :computed-action="subconto_type_select_setting[charge.account_detail_2_type].computed_action"
                          :computed-getter="subconto_type_select_setting[charge.account_detail_2_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="charge.row_num"
                          :show-select-on-focus="false"
                          small
                          transparent
                          :required="true"
                          :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_2_type].select_button_object.element_title,
                                show: true
                              }"/>
                    </div>
                    <div class="document-table-col" v-if="show_detail_3">
                      <select_input
                          v-if="charge.account_detail_3_type"
                          v-model="charge.account_detail_3_value"
                          :computed-action="subconto_type_select_setting[charge.account_detail_3_type].computed_action"
                          :computed-getter="subconto_type_select_setting[charge.account_detail_3_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="charge.row_num"
                          :show-select-on-focus="false"
                          small
                          transparent
                          :required="true"
                          :select-button-object="{
                                name: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[charge.account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"/>
                    </div>
                  </div>
                  <div class="document-table-col" style="flex: 0 0 90px">
                    <number_input v-model="charge.percent" small transparent :required="true" v-decimal></number_input>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1"
             :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
  UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
  REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS,
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import amortisationCalcWayAPI from "@/utils/axios/accounting/amortisation_calc_way";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {maxBy} from "lodash";
import {subconto_type_select_setting} from "@/utils/accounting";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'amortisation_calc_way_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_AmortisationCalcWay",
  mixins: [ModalComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
  },
  data() {
    return {
      subconto_type_select_setting,
      short_name: this.item.short_name,
      full_name: this.item.full_name,
      code: this.item.code || '',
      table: [],
      charts: [],
      show_detail_1: false,
      show_detail_2: false,
      show_detail_3: false,
    }
  },
  methods: {
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    getDetailsShow() {
      this.show_detail_1 = !!this.table.filter(item => item.account_detail_1_type).length
      this.show_detail_2 = !!this.table.filter(item => item.account_detail_2_type).length
      this.show_detail_3 = !!this.table.filter(item => item.account_detail_3_type).length
    },
    addRow() {
      const charge_703_id = '92'
      const chart_703 = this.charts.find(c => c.code === charge_703_id) || {}

      let details = this.get_account_details(chart_703.id)

      this.table.push(
          {
            chart_of_account_id: chart_703.id,
            account_detail_1_value: null,
            account_detail_1_type: details.account_detail_1.type,
            account_detail_2_value: null,
            account_detail_2_type: details.account_detail_2.type,
            account_detail_3_value: null,
            account_detail_3_type: details.account_detail_3.type,
            percent: 100,
            row_num: this.getNewRowNum('table'),
          }
      )
      this.getDetailsShow()
    },
    deleteTableRow(payload) {
      this.table.splice(this.table.indexOf(payload), 1)
      this.getDetailsShow()
    },
    afterPayChartAccountChange(payload) {
      const row = this.table.find(item => item.row_num === payload.row_num)
      if (row) {
        let details = {}
        if ('text' in payload && 'value' in payload) {
          details = this.get_account_details(payload.value)
        } else {
          details = this.get_account_details(payload.id)
        }
        row.account_detail_1_type = details.account_detail_1.type
        row.account_detail_1_value = null
        row.account_detail_2_type = details.account_detail_2.type
        row.account_detail_2_value = null
        row.account_detail_3_type = details.account_detail_3.type
        row.account_detail_3_value = null
      }
      this.getDetailsShow()
    },
    clearDialog() {
      const payload = {
        text: `Очистити таблицю з товарами?`,
        accept_button: true,
        id: 'clear_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    fetch_data_by_id() {
      if (this.isNew) return

      amortisationCalcWayAPI.get_by_id({item_id: this.itemId})
          .then(response => response.data)
          .then(data => {
            this.code = data.code || false
            this.short_name = data.short_name || null
            this.full_name = data.full_name || null

            this.table = data.table.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.getDetailsShow()
          })
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.short_name = this.item.short_name
      this.full_name = this.item.full_name
      this.code = this.item.code
      this.table = []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення способу нарах.амортизації № ${this.short_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Коротка назва, повна назва - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        short_name: this.short_name,
        full_name: this.full_name,
        code: this.code,
        table: this.table
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_AMORTISATION_CALC_WAY_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.code = payload.code
              this.short_name = payload.short_name
              this.full_name = payload.full_name
              this.table = []

              this.fetch_data_by_id()
              this.getChartsOfAccounts()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_AMORTISATION_CALC_WAY_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_table') {
                if (payload.answer) {
                  this.table = []
                  this.getDetailsShow()
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings',
    }),
  },
}
</script>

<style scoped lang="scss">

</style>